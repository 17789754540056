import { Ref } from "vue";

/* eslint-disable */
export default class CommonUtil {
  public static numberOnly(value: string): string {
    if (!value) return "";
    const regex = /[0-9|.|-]/g;
    const dist = value.match(regex);
    if (dist) {
      return dist.join('');
    }
    return "";
  }

  public static strToInt(value: string): number {
    value = this.numberOnly(value);
    if (!value) return 0;
    return parseInt(value);
  }

  public static strToFloat(value: string): number {
    value = this.numberOnly(value);
    if (!value) return 0;
    return parseFloat(value);
  }

  public static getApiBaseUrl(): string {
    const API_BASE_URL: string = process.env.VUE_APP_API_BASE_URL!;
    return API_BASE_URL;
  }

  public static getRefComponentValue(ref: Ref<null>, key: string): any | null {
    if (ref.value) {
      const comp: any = ref.value;
      return comp[key];
    }
    return null;
  }

  public static dateToString(date: Date): string {
    // return date.toLocaleString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('.', '').replaceAll(' ', '-');
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const month_str = month < 10 ? `0${month}` : `${month}`;
    const day_str = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${month_str}-${day_str}`;
  }

  public static paramsToObject(params: any): any {
    const result: any = {};
    let itemcodes = [];
    let location = [];
    for (const key in params.value) {
      if (params.value.hasOwnProperty(key)) {
        let k = "";
        switch (key) {
          case "area": k = "location"; break;
          case "dateFrom": k = "from_date"; break;
          case "dateTo": k = "to_date"; break;
          case "limit": k = "pageSize"; break;
          case "sjyType": k = "point"; break;
          case "gwannae": k = "loc_names"; break;
          case "gwannaeExclude": k = "search_local_use"; break;
          default: k = key; break;
        }

        const element = params.value[key];
        if (element || (k === "location" && element === 0)) {
          //업종3개
          if (key === "upjong1" || key === "upjong2" || key === "upjong3") {
            if (element != null && element != "null"){
              itemcodes.push(element);
            }
            continue;
          }
          //관내제외, 단일업종
          if ( (k === "search_local_use" || k === "itemcode_only") && element === true) {
            result[k] = "Y";
            continue;
          }
          //전국포함
          if (k === "countryInclude" && element === true) {
            location.push('0');
            location.push(result["location"]);
            result["location"] = location;
            continue;
          }
          //사정률 범위
          if (k === "sjyRangeFrom" || k === "sjyRangeTo") {
            continue;
          }

          result[k] = element;
        } else {
          // console.log(key + " is null");
        }
      }
    }

    //사정율 범위 조합
    if (params.value.sjyRangeFrom && params.value.sjyRangeTo) {
      const sjyRangeFrom = params.value.sjyRangeFrom ? params.value.sjyRangeFrom : 0;
      const sjyRangeTo = params.value.sjyRangeTo ? params.value.sjyRangeTo : 0;
      result["yegarng"] = `${sjyRangeFrom}/${sjyRangeTo}`;
    }

    // Add itemcodes to the result object
    if (itemcodes.length > 0) {
      // console.log("itemcodes : " + itemcodes);
      result["itemcodes"] = itemcodes;
    }

    // console.log("result : ", result);

    return result;
  }

  // public static paramsToString(params: any): string {
  //   let result = "";
  //   let k = "";
  //   let itemcodes = "";
  //   for (const key in params.value) {
  //     if (params.value.hasOwnProperty(key)) {
  //       switch (key) {
  //         case "area": k = "location"; break;
  //         case "dateFrom": k = "from_date"; break;
  //         case "dateTo": k = "to_date"; break;
  //         case "limit": k = "pageSize"; break;
  //         case "sjyType": k = "point"; break;
  //         case "gwannae": k = "loc_names"; break;
  //         case "gwannaeExclude": k = "search_local_use"; break;
  //         default: k = key; break;
  //       }

  //       const element = params.value[key];
  //       if (element || (k == "location" && element == 0) ) {
  //         //업종3개
  //         if (key == "upjong1" || key == "upjong2" || key == "upjong3") {
  //           itemcodes += `itemcodes=${element}&`;
  //           continue;
  //         }
  //         //관내제외
  //         if (k == "search_local_use" && element == true) {
  //           result += `${k}=Y&`;
  //           continue;
  //         }
  //         //전국포함
  //         if (k == "countryInclude" && element == true) {
  //           result += `location=0&`;
  //           continue;
  //         }
  //         //사정률 범위
  //         if (k == "sjyRangeFrom" || k == "sjyRangeTo") {
  //           continue;
  //         }

  //         result += `${k}=${element}&`;
  //       } else {
  //         // console.log(key + " is null");
  //       }
  //     }
  //   }

  //   //사정율 범위 조합
  //   if (params.value.sjyRangeFrom && params.value.sjyRangeTo) {
  //     const sjyRangeFrom = params.value.sjyRangeFrom ? params.value.sjyRangeFrom : 0;
  //     const sjyRangeTo = params.value.sjyRangeTo ? params.value.sjyRangeTo : 0;
  //     result += `yegarng=${sjyRangeFrom}/${sjyRangeTo}&`;
  //   }

  //   result += itemcodes;
  //   // console.log(params.value);
  //   // console.log("params : ");
  //   // console.log(result);
  //   return result;
  // }

  public static intcomma(value: number): string {
    if (!value) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public static strToNumber(value: string): number {
    value = this.numberOnly(value);
    if (!value) return 0;
    return Number(value);
  }

  public static numberToStr(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public static ceil1000(val: number): number {
    return Math.ceil(val / 1000) * 1000;
  };

  public static priceDecision(price: number, decision: '올림' | '내림' | '반올림' | '버림' | '올림1000'): number {
    switch (decision) {
      case '올림': return Math.ceil(price);
      case '내림': return Math.floor(price);
      case '반올림': return Math.round(price);
      case '버림': return Math.trunc(price);
      case '올림1000': return Math.ceil(price / 1000) * 1000;
    }
  };

  public static convertComputedSjy(sjy: number, sjyType: '0%' | '100%') {
    if (sjyType === '100%') {
      sjy = sjy / 100
    } else if (sjyType === '0%') {
      sjy = 1 + sjy / 100;
    }
    // sjy = Math.round(sjy * 100000) / 100000; // 소수점 5자리까지만 허용
    return sjy;
  }

  public static convertSjyType(sjy: number, toType: '0%' | '100%') {
    // 사정률을 0% -> 100%로 변환하거나 100% -> 0%로 변환한다.
    // (100% -> 0%) : 101.0429 -> 1.0429 | 98.7610 -> -1.2391
    // (0% -> 100%) : 1.0429 -> 101.0429 | -1.2391 -> 98.7610
    if (toType === '0%') {
      sjy = (sjy / 100 - 1) * 100;
    } else {
      sjy = (1 + sjy / 100) * 100;
    }
    sjy = Math.round(sjy * 100000) / 100000; // 소수점 5자리까지만 허용
    return sjy;
  }

  public static integerOnly(value: string): number {
    let ret = Number(value.replace(/[^0-9|\-|+]/g, ''));
    if (isNaN(ret)) {
      ret = 0;
    }
    return ret;
  }

  // public static decimalOnly(value: string): number {
  //   let ret = Number(value.replace(/[^0-9|\-|+|.]/g, ''));
  //   if (isNaN(ret)) {
  //     ret = 0;
  //   }
  //   return ret;
  // };

  public static decimalOnly(value: string): string {
    // 숫자, 소수점, 부호만 남기기
    let num = value.replace(/[^0-9.\-+]/g, '');
    
    // 숫자로 변환 후 NaN 방지
    let ret = Number(num);
    if (isNaN(ret)) {
        return "0";
    }

    // 정수와 소수점 분리
    let [integerPart, decimalPart] = ret.toString().split('.');

    // 3자리마다 콤마 추가
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // 소수점이 있는 경우 소수점 이하도 붙이기
    return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
}


  public static yegaRangeCorrect(value: string): string {
    const replaced = value.replace(/[^0-9|\-|+|.]/g, '');
    let ret = Number(replaced);
    if (isNaN(ret)) {
      ret = 0;
    }
    if (ret < 0) {
      return ret.toString();
    } else if (ret > 0) {
      return "+" + ret.toString();
    } else {
      return ret.toString();
    }
  }

  public static yegaRangeParse(value: string): { from: number, to: number } {
    // -2.5/+3
    const splitters = ['/', '~', '|'];
    let from = 0;
    let to = 0;
    if (value.trim() !== '') {
      for (const splitter of splitters) {
        if (value.includes(splitter)) {
          const splited = value.split(splitter);
          from = Number(splited[0]);
          to = Number(splited[1]);
          if (isNaN(from)) {
            from = 0;
          }
          if (isNaN(to)) {
            to = 0;
          }
          break;
        }
      }
    }
    return { from, to };
  }

  public static truncateTo4Decimal(value: number): number {
    return Math.trunc(value * 10000) / 10000;
  }

  public static yegatypeToText(yegatype: string): string {
    switch (yegatype) {
      case '00':
        return '비예가';
      case '11':
        return '단일예가';
      case '17':
        return '복수예가 : 3(추첨예가)/7(총예가)';
      case '20':
        return '복수예가 : 3(추첨예가)/10(총예가)';
      case '23':
        return '복수예가 : 4(추첨예가)/10(총예가)';
      case '25':
        return '복수예가 : 4(추첨예가)/15(총예가)';
      case '27':
        return '복수예가 : 3(추첨예가)/15(총예가)';
      case '28':
        return '복수예가 : 2(추첨예가)/5(총예가)';
      case '29':
        return '복수예가 : 3(추첨예가)/5(총예가)';
      case '30':
        return '복수예가 : 7(추첨예가)/15(총예가)';
      case '31':
        return '복수예가 : 5(추첨예가)/15(총예가)';
      case '32':
        return '복수예가 : 2(추첨예가)/15(총예가)';
      case '33':
        return '복수예가 : 1(추첨예가)/3(총예가)';
      case '90':
        return '공고서참조';
    }
    return '';
  }

  public static contractToText(contract: string): string {
    switch (contract) {
      case '10':
        return '일반';
      case '20':
        return '제한';
      case '30':
        return '지명';
      case '40':
        return '수의';
      case '50':
        return '장기';
      case '60':
        return '복수경쟁';
      case '70':
        return '역경매';
      case '80':
        return '실적';
    }
    return '';
  }

  public static formatMoney(value?: number | string): string {
    if (value == 0) {
      return "";
    }
    return value ? `${value} 원` : "";
  };

  public static formatDate(date?: number | string | null): string {
    return date == 0 || date === "0" || date === null || date === undefined ? "" : String(date);
  };


  public static formatText(text?: string): string {
    return text ? text.replace(/\r\n|\n/g, "<br>") : "";
  }

  
  
}